import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import { Link } from 'gatsby';
import Image from '../components/Image';
import OutboundLink from '../components/OutboundLink';
import Icon from '../components/Icon';
import Video from '../components/Video';

const AboutPage = () => (
  <Layout title="About">
    {/* ABOUT HERO */}
    <Section className="hero">
      <Container>
        <Row>
          <Column size={12}>
            <Image filename="img-hero-about.jpg" alt="About Hero Image" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* INTRO SECTION */}
    <Section className="intro-section">
      <Container>
        <Row>
          <Column size={10}>
            <h1 className="page-title">About</h1>
            <h2 className="page-subhead">
              Connecting Students to the Economics of the World Around Them
            </h2>
            <p>
              Econ Essentials resources explore the dynamic ways that students’
              lives connect to the global economy, while equipping students with
              an understanding of core economic principles through the lens of
              food, fuel and finance. These standards-aligned interactive
              learning resources create timely real-world connections to
              everyday life, all of which are brought to life by the
              industry-leading insights of Futures Fundamentals.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* CLASSROOM TESTIMONIAL */}
    <Section className="classroom-testimonial">
      <Container>
        <Row>
          <Column size={10} offset={1}>
            <h2 className="page-subhead">Classroom Testimonial</h2>
            <p>
              See how the Ogden International School utilizes Econ Essentials in
              their classrooms.
            </p>
            <Video
              guidSrc="91254405-65f6-4596-bd6f-05a3604ac749"
              title="Ogden International School utilizes Econ Essentials in
              their classrooms"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* FUTURES FUNDAMENTALS */}
    <Section className="econ-essentials-ff">
      <Container>
        <Row>
          <Column size={9}>
            <h3 className="ee-heading">EconEssentials is powered by</h3>
            <Image
              className="futures-fundamentals__logo"
              filename="futures-fundamentals-logo.png"
            />
            <p>
              Futures Fundamentals is an industry-wide education initiative
              designed to simplify and explain complex market topics. Through
              interactive features and rich content, the website explains the
              role of futures markets in everyday life and provides information
              on the derivatives industry as a whole. Students can explore
              important topics like futures, hedging and speculating through
              videos, quizzes, games and easy-to-understand infographics.
            </p>
          </Column>
        </Row>
        <Row className="assets">
          <Column size={3} className="asset box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-about-asset-card-1.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Get the Basics</h4>
              <h3 className="asset__title">Why do Futures Matter?</h3>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="https://www.futuresfundamentals.org/get-the-basics/heres-why-futures-matter-to-you/"
                target="_blank"
                className="asset__file"
              >
                <Icon name="externallink" />
                Visit Website
              </OutboundLink>
            </div>
          </Column>
          <Column size={3} className="asset box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-about-asset-card-2.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Get the Basics</h4>
              <h3 className="asset__title">
                Introduction to Futures & Derivatives
              </h3>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="https://www.futuresfundamentals.org/get-the-basics/introduction-to-derivatives/"
                target="_blank"
                className="asset__file"
              >
                <Icon name="externallink" />
                Visit Website
              </OutboundLink>
            </div>
          </Column>
          <Column size={3} className="asset box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-about-asset-card-3.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">See the Impact</h4>
              <h3 className="asset__title">Food Prices Explained</h3>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="https://www.futuresfundamentals.org/see-the-impact/agriculture-futures/food-prices-explained/"
                target="_blank"
                className="asset__file"
              >
                <Icon name="externallink" />
                Visit Website
              </OutboundLink>
            </div>
          </Column>
          <Column size={3} className="asset box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-about-asset-card-4.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Explore the Marketplace</h4>
              <h3 className="asset__title">Who Trades Futures Markets?</h3>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="https://www.futuresfundamentals.org/explore-the-marketplace/the-wide-world-of-futures-traders/"
                target="_blank"
                className="asset__file"
              >
                <Icon name="externallink" />
                Visit Website
              </OutboundLink>
            </div>
          </Column>
        </Row>
        <Row className="pt-2 pb-2">
          <Column size={8}>
            <p className="bold">
              Continue your learning journey today on Futures Fundamentals and
              discover how futures play an essential role in the world around
              us.
            </p>
            <OutboundLink
              to="https://www.futuresfundamentals.org/"
              target="_blank"
              className="button"
            >
              Learn More <Icon name="externallink" />
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* DISCOVERY EDUCATION */}
    <Section className="de-callout bb-orange">
      <Container>
        <Row>
          <Column size={9}>
            <Image filename="de-logo-large.png" className="de-callout__logo" />
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in over 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.,
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners.
            </p>
            <OutboundLink
              to="https://www.discoveryeducation.com/"
              className="mt-1"
            >
              <Icon name="externallink" className="mr-0.5" />
              Visit Website
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* SIGNUP FOR UPDATES */}
    <Section className="signup-updates">
      <Container>
        <Row>
          <Column size={7}>
            <h2>Sign Up for Updates</h2>
            <p>
              Want to be among the first to learn about exciting new resources
              and upcoming learning opportunities? Stay connected with Econ
              Essentials and sign up to receive updates today!
            </p>
            <OutboundLink
              to="https://info.discoveryeducation.com/Econ-Essentials-Powered-by-Futures-Fundamentals_Opt-Ins.html"
              className="button mt-1"
            >
              Learn More <Icon name="caratright" />
            </OutboundLink>
          </Column>
          <Column size={4} offset={1}>
            <Image filename="hp-signip-map.svg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* PURE CSS STANDARDS-ALIGNED RESOURCES POPUP |
        THIS SHOULD USE THE MODAL COMPONENT */}
    <div id="sar-modal" className="overlay">
      <div className="sar">
        <a className="close" href="#">
          &times;
        </a>
        <h2 className="sar__heading">Standards-Aligned Resources</h2>
        <p>
          Download the standards below to reference the corresponding resource
          and standards connection.
        </p>
        <ul className="sar__resources">
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Correlation-OverviewChart.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Overview of National Standards
              Correlations
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Voluntary-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Voluntary National Content Standards in
              Economics
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-PersFin-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Personal Financial
              Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-SS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> C3 Framework for Social Studies State
              Standards
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-FCS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Family and
              Consumer Sciences Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Business-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Business Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-CT-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Common Career Technical Core
            </OutboundLink>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
